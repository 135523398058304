<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <user-list-conversations />

  </section>
</template>

<script>
import {
  BBreadcrumb,
} from 'bootstrap-vue'
import UserListConversations from '../components/UserListConversations.vue'

export default {
  components: {
    UserListConversations,
    BBreadcrumb,
  },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Conversas',
          active: true,
        },
      ],
    }
  },

  watch: {
  },

  methods: {
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
